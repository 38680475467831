<template>
  <div class="chess-wrapper">
    <div class="grey-bg" :class="{ details: showResident }">
      <div v-for="block in blocks" :key="block.id">
        <BlockItem
          :block="block"
          :isEditBlock="isEditBlock"
          @delete="deleteBlock(block.id)"
          @update="updateBlock"
        />
        <AddEntrance :block="block" @create="createEntrance" />
        <div :key="`content-${block.id}`">
          <div v-if="apartmentsLoading" class="chess-skeleton-wrapper">
            <v-skeleton-loader type="card-heading" class="chess-skeleton-header"></v-skeleton-loader>
            <v-skeleton-loader type="table-cell, avatar@5" class="chess-skeleton-floor"></v-skeleton-loader>
            <v-skeleton-loader type="table-cell, avatar@5" class="chess-skeleton-floor"></v-skeleton-loader>
            <v-skeleton-loader type="table-cell, avatar@5" class="chess-skeleton-floor"></v-skeleton-loader>
            <v-skeleton-loader type="table-cell, avatar@5" class="chess-skeleton-floor"></v-skeleton-loader>
            <v-skeleton-loader type="table-cell, avatar@5" class="chess-skeleton-floor"></v-skeleton-loader>
            <v-skeleton-loader type="button" class="chess-skeleton-button"></v-skeleton-loader>
          </div>
          <v-card class="d-flex flex-wrap" flat color="transparent">
            <EntranceItem
              v-for="(entrance, key) in enterences[block.id]"
              :key="key"
              :entrance="entrance"
              :block="block"
              @hideSkeleton="apartmentsLoading = false"
              @showResident="toggleShowResident"
              @addResident="toggleAddResident"
              @refreshChess="init"
            />
          </v-card>
        </div>
      </div>
      <div class="create-block">
        <input
          type="text"
          class="add-block-input"
          placeholder="Введите название блока"
          ref="blockName"
          v-if="isInput"
          v-model="num"
          @keyup.enter="createBlock(num)"
        />
        <div class="add-block-title" v-if="!isInput" @click="showCreateBlock">
          Добавить блок
        </div>
        <div
          class="create-block-btn"
          v-if="isInput"
          @click="createBlock(num)"
        >
          <img src="../../assets/images/Active.svg" alt="" />
        </div>
        <div
          class="cancel-block-btn"
          v-if="isInput"
          @click="
            isInput = false;
            num = '';
          "
        >
          <img src="../../assets/images/cancel-btn.svg" alt="" />
        </div>
      </div>
    </div>
    <div v-if="showResident">
      <div v-if="resident" class="chess-side-modal" style="max-height: 100vh">
        <div class="side-modal-wrap">
          <div class="close" @click="toggleCloseResident">
            <v-icon>mdi-close</v-icon>
          </div>

          <h2 class="title">
            Квартира №{{ resident.apt && resident.apt.number }}
          </h2>
          <div class="address-row">
            <div class="address">
              <div class="type">Блок</div>
              <div class="num">
                {{ resident.block && resident.block.number }}
              </div>
            </div>
            <div class="address">
              <div class="type">Подъезд</div>
              <div class="num">{{ resident.entrance }}</div>
            </div>
            <div class="address">
              <div class="type">Этаж</div>
              <div class="num">{{ resident.apt && resident.apt.floor }}</div>
            </div>
          </div>
          <div class="resident-info">
            <div class="avatar" v-if="resident.avatar">
              <img :src="resident.avatar" alt="" />
            </div>
            <div class="text">
              <div class="name">
                {{ resident.personal_account.owner }}
              </div>
              <div>
                <span class="account-num">
                  Лиц. счет: {{ resident.personal_account.personal_account }}
                </span>
                <v-chip label class="provider">
                  {{ residentProvider.title }}
                </v-chip>
              </div>
            </div>
          </div>
          <div class="resident-contacts" v-if="resident.personal_account">
            <div class="head-info" @click="toggleShowResidentContacts">
              Контактные данные
              <img src="@/assets/images/grey-arrow.svg" alt="" />
            </div>
            <div v-show="showResidentContacts" class="body-info">
              <div class="executor-description">
                <div class="item">
                  <div class="left">E-mail</div>
                  <div v-if="resident.building_owner" class="right">
                    {{ resident.building_owner.email || "-" }}
                  </div>
                  <div v-else class="right"> - </div>
                </div>
                <div class="item">
                  <div class="left">Телефон</div>
                  <div v-if="resident.building_owner" class="right">
                    {{ resident.building_owner.phone | VMask("+# (###) ###-##-##") }}
                  </div>
                  <div v-else class="right"> - </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>Житель не авторизован в системе</div>
          <v-container
            class="notes-about-resident"
            style="padding: 0; position: relative"
            fluid
          >
            <v-textarea
              v-model="resident.note"
              @change="updateNote($event)"
              placeholder="Заметки по квартире и жителям"
              hide-details="auto"
              auto-grow
              counter="100"
              maxlength="100"
              class="pt-3"
            ></v-textarea>
            <div class="loading-area" v-if="noteUpdating">
              <v-progress-circular
                indeterminate
                color="#5b6cff"
                size="60"
                width="2"
              ></v-progress-circular>
            </div>
          </v-container>
          <div class="resident-access">
            <h6>Доступы</h6>
            <!-- TODO: fix to get list of access-->
            <span
              v-if="resident && resident.members && !resident.members.length"
              >Житель не открывал доступы</span
            >
            <template v-else>
              <div class="resident-info">
                <div class="avatar">
                  <img src="@/assets/images/avatar.svg" alt="" />
                </div>
                <div class="text">
                  <div class="name">Айгул Саматова</div>
                  <div class="account-num">Жена</div>
                </div>
                <div class="delete">
                  <span></span>
                </div>
              </div>
              <div class="resident-info">
                <div class="avatar">
                  <img src="@/assets/images/avatar.svg" alt="" />
                </div>
                <div class="text">
                  <div class="name">Мелис Алибеков</div>
                  <div class="account-num">квартирант</div>
                </div>
              </div>
              <div class="resident-info">
                <div class="avatar">
                  <img src="@/assets/images/avatar.svg" alt="" />
                </div>
                <div class="text">
                  <div class="name">Самал Смакова</div>
                  <div class="account-num">дочь</div>
                </div>
              </div>
            </template>
          </div>
          <div class="btn-row">
            <div class="edit-btn" @click="toggleEditOwner(resident)">
              Редактировать
            </div>
            <div class="delete-btn" @click="toggleDeleteOwner(resident)">
              Удалить
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddResident">
      <AddResident
        :apt="addResidentApt"
        :providersList="providersList"
        @close="toggleAddResident"
        @created="init"
        @delete="deleteFlat"
      />
    </div>
    <div v-if="showEditOwner">
      <EditOwner
        :resident="resident"
        :providersList="providersList"
        @closeModal="toggleEditOwner"
        @closeResident="toggleCloseResident"
        @delete="deleteFlat"
      />
    </div>
    <div v-if="showDeleteOwner">
      <DeleteUser
        :item="resident"
        :type="`residents`"
        @close="toggleDeleteOwner"
        @closeResident="toggleCloseResident"
        @updateList="init"
      />
    </div>
  </div>
</template>
<script>
import {
  getBlockEnterencesApi,
  getBlockApartamentsApi,
  getProvidersApi,
  getBuildingOwnerApi,
  addNoteToOwner,
  addChessBlock,
  addEntranceApi,
  deleteApartamentApi,
  updateBlockApi,
} from "@/api/buildings";
import { getBlocksApi, deleteBlockApi } from "@/api/companies";
import DeleteUser from "@/components/modals/DeleteUser";

export default {
  components: {
    DeleteUser,
    AddResident: () => import("@/components/modals/AddResident.vue"),
    EditOwner: () => import("@/components/modals/EditOwner.vue"),
    EntranceItem: () => import("@/components/chess/EntranceItem.vue"),
    DeletionConfirm: () => import("@/components/modals/DeletionConfirm.vue"),
    BlockItem: () => import("@/components/modals/BlockItem.vue"),
    AddEntrance: () => import("@/components/modals/AddEntrance.vue"),
  },
  data: () => ({
    showEditOwner: false,
    showDeleteOwner: false,
    showAddResident: false,
    addResidentApt: {},
    showResident: false,
    resident: undefined,
    showResidentContacts: false,
    blocks: [],
    enterences: {},
    providersList: undefined,
    noteUpdating: false,
    isInput: false,
    showEditBlock: false,
    num: "",
    isEditBlock: false,
    show: false,
    apartmentsLoading: false,
  }),
  watch: {
    $route: {
      handler(to) {
        this.init();
      },
    },
  },
  computed: {
    residentProvider() {
      return this.resident
        ? this.providersList.find(
            (provider) =>
              provider.id === this.resident.personal_account.provider
          )
        : "";
    },
  },
  created() {
    this.init();
  },
  methods: {
    toggleEditOwner() {
      this.showEditOwner = !this.showEditOwner;
    },
    async deleteFlat(id) {
      try {
        await deleteApartamentApi({ id });
        this.init();
        this.$store.commit('SHOW_SNACKBAR', 'deleteApartmentSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Квартира успешно удалена');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteApartmentErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении квартиры');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    toggleDeleteOwner() {
      this.showDeleteOwner = !this.showDeleteOwner;
    },
    toggleAddResident(apt) {
      this.showAddResident = !this.showAddResident;
      this.addResidentApt = this.showAddResident ? { ...apt } : {};
    },
    async updateBlock(data) {
      try {
        await updateBlockApi({ data });
        this.init();
        this.$store.commit('SHOW_SNACKBAR', 'updateBlockSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Блок успешно обновлён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'updateBlockErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при обновлении блока');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async toggleShowResident(apt, block, entrance) {
      this.showResident = true;
      this.$emit("resizeTabsRow");
      const res = await getBuildingOwnerApi(apt.apartment_id);
      this.resident = {
        apt,
        block,
        entrance,
        building_owner: res.data.building_owner,
        personal_account: res.data.personal_account,
        members: res.data.members,
        avatar:
          res.data.building_owner?.avatar ||
          require("@/assets/images/avatar.svg"),
      };
    },
    toggleCloseResident() {
      this.showResident = false;
      this.$emit("resizeTabsRow");
    },
    toggleShowResidentContacts() {
      this.showResidentContacts = !this.showResidentContacts;
    },
    toggleShowEditBlock() {
      let edit_block = document.getElementById("edit-block");
      if (!this.showEditBlock) {
        edit_block.style.display = "none";
      } else {
        edit_block.style.display = "block";
      }
      this.showEditBlock = !this.showEditBlock;
    },
    async getBlockEnterences() {
      if (this.enterences === {}) {
        this.$store.commit("ADD_LOADING", "APARTMENTS_LOADING");
        this.apartmentsLoading = true;
      };
      let results = [];
      const blocks = this.blocks;
      blocks.forEach((block, i) => {
        results.push(getBlockEnterencesApi(block.id));
      });
      const enterences = await Promise.all(results);
      this.enterences = Object.fromEntries(
        enterences.map((res, i) => [blocks[i]?.id, res.data])
      );
    },
    async showCreateBlock() {
      this.isInput = true;
      this.$nextTick(() => this.$refs.blockName.focus());
    },
    async deleteBlock(block_id) {
      try {
        await deleteBlockApi({ block: block_id });
        this.init();
        this.$store.commit('SHOW_SNACKBAR', 'deleteBlockSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Блок успешно удалён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteBlockErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении блока');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async startEditBlock() {
      this.isEditBlock = true;
      this.$nextTick(() => this.$refs.newBlock.focus());
    },
    async createEntrance(newEntranceName, block) {
      try {
        await addEntranceApi({
          building_type: 1,
          block: block,
          entrance: newEntranceName,
        });
        this.init();
        this.$store.commit('SHOW_SNACKBAR', 'createEntranceSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Подъезд успешно добавлен');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        if (err.response.data.msg.entrance) {
          let errorMessage = err.response.data.msg.entrance[0];
          this.$store.commit('SHOW_SNACKBAR', 'createEntranceErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'createEntranceErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении подъезда');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        };
        throw err;
      };
    },
    async createBlock(num) {
      try {
        await addChessBlock({ number: num });
        this.isInput = false;
        this.num = "";
        this.init();
        this.$store.commit('SHOW_SNACKBAR', 'addBlockSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Блок успешно добавлен');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'addBlockErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении блока');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async getBlocks() {
      const res = await getBlocksApi();
      this.blocks = res.data;
    },
    async init() {
      //Загрузка блоков и подъездов
      await this.getBlocks();
      this.getBlockEnterences();
      this.getProviders();
    },
    async getProviders() {
      let res = await getProvidersApi();
      this.providersList = res.data.result;
    },
    async updateNote(event) {
      try {
        this.noteUpdating = true;
        await addNoteToOwner({
          id: this.resident.apt.apartment_id,
          note: event,
        });
        this.$store.commit('SHOW_SNACKBAR', 'addNoteSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Заметка успешно добавлена');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        if (err.response.data.message) {
          let errorMessage = err.response.data.message;
          this.$store.commit('SHOW_SNACKBAR', 'addNoteErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', `${errorMessage}. Добавьте жителя как владельца`);
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'addNoteErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении заметки');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        };
        this.resident.note = null;
        throw err;
      } finally {
        this.noteUpdating = false;
      };
    },
  },
};
</script>

<style>
.block .edit-block {
  color: #5b6cff;
  cursor: pointer;
  margin-left: 15px;
}
.block .delete-block {
  color: #ff7171;
  cursor: pointer;
  margin-left: 15px;
}
.block .edit-block-btn {
  color: #5b6cff;
  cursor: pointer;
  margin-left: 15px;
}
.block .delete-block-btn {
  color: #ff7171;
  cursor: pointer;
  margin-left: 15px;
}
.blocks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.blocks .dots-open {
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.blocks .dots-open:hover {
  background: #efeff2;
}
.add-block-input {
  line-height: 130%;
  font-weight: 600;
  width: 320px;
  height: 46px;
  margin-left: 0;
  background: #f0f0f3;
  font-size: 20px;
  padding: 0px 12px;
}
.add-block-input input::placeholder {
  font-size: 20px;
  font-weight: 600;
}
.add-block-title {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  color: #7b7e97;
}
.cancel-block-btn {
  margin-left: 16px;
  cursor: pointer;
}
.loading-area {
  border-radius: 8px;
  position: absolute;
  background-color: rgba(250, 250, 250, 0.3);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, -100%);
}
.gradient-border {
  border-image: linear-gradient(
      var(--angle),
      #81e3b4,
      lime,
      #bec4ff,
      blue,
      #000000
    )
    1;
  animation: 1s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
</style>
